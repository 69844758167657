import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography as MDTypography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useMemo, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import { ImageSearch } from "@mui/icons-material";
import RecognitionDetailsModal from "components/RecognitionDetailsModal";
import heic2any from "heic2any";
import { getIconByYardstikStatus } from "utils/helpers/applicant";
import YardstikAttachmentModal from "components/YardstikAttachmentModal";

const IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "gif", "jfif", "svg", "webp"];

const HEICImageView = ({ file, height }) => {
  const [heicPreview, setHeicPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const convertHeicToImage = async () => {
    try {
      const response = await fetch(file.presignedUrl);
      const blob = await response.blob();
      const convertedBlob = await heic2any({ blob, toType: "image/jpeg", quality: 0.5 });
      const previewUrl = URL.createObjectURL(convertedBlob);
      setHeicPreview(previewUrl);
      setIsLoading(false);
    } catch (error) {
      console.error("Error converting HEIC image:", error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  // Memoize the HEIC preview conversion based on the file's presigned URL
  useMemo(() => {
    setIsLoading(true);
    setHasError(false);
    setHeicPreview(null); // Reset preview if the file changes
    if (file?.presignedUrl) {
      convertHeicToImage();
    }
  }, [file]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height={height}
      >
        <MDTypography variant="h6">Loading...</MDTypography>
        <CircularProgress sx={{ mt: 1 }} />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height={height}
      >
        <MDTypography variant="h6" color="error" mt={2}>
          Preview not available
        </MDTypography>
      </Box>
    );
  }

  if (heicPreview) {
    return <img src={heicPreview} alt="HEIC Preview" height={height} />;
  }

  return null;
};

const FileViewer = ({
  file,
  currentApplicant,
  IMAGE_SERVER,
  company,
  size = 100,
  onDelete,
  isUser,
  currentLoggedUser,
  editFileNameHandler,
  confirmFileNameChanges,
  cancelFileNameChanges,
}) => {
  const [usePresigned, setUsePresigned] = useState(true);
  const [recognitionDetailModalOpen, setRecognitionDetailModalOpen] = useState(false);
  const [yardstikModalOpen, setYardstikModalOpen] = useState(false);

  const getDirectUrl = () => {
    return `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.name}`;
  };

  const handleFileOpen = () => {
    if (usePresigned && file.presignedUrl) {
      fetch(file.presignedUrl, { method: "HEAD" })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Presigned URL failed");
          }
          window.open(file.presignedUrl);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("Presigned URL failed, falling back to direct URL");
          setUsePresigned(false);
          window.open(getDirectUrl());
        });
    } else {
      window.open(getDirectUrl());
    }
  };

  const getFilePreview = (height, insideView) => {
    if (IMAGE_SERVER && IMAGE_EXTENSIONS.includes(file.docType.toLowerCase())) {
      if (insideView)
        return (
          <img
            src={getDirectUrl()}
            alt={`${file.type} preview`}
            style={{ width: "100%" }}
            onError={() => setUsePresigned(false)}
          />
        );
      return (
        <img
          src={getDirectUrl()}
          alt={`${file.type} preview`}
          height={height}
          onError={() => setUsePresigned(false)}
        />
      );
    }
    if (file.docType.toLowerCase() === "heic" || file.docType.toLowerCase() === "heif") {
      return <HEICImageView file={file} height={height} />;
    }
    if (company?.imageUrl && company?.uploadUrl) {
      const baseUrl = getCommonBaseImageUrl(company);
      if (file.docType.toLowerCase() === "pdf") {
        if (insideView)
          return (
            <object
              data={getDirectUrl()}
              type="application/pdf"
              style={{ width: "100%", height: "80vh" }}
            >
              <p>It appears you do not have a PDF plugin for this browser.</p>
            </object>
          );
        return <img src={`${baseUrl}/static/pdf-icon.png`} alt="PDF" width={size} height={size} />;
      }
      if (file.docType.toLowerCase() === "docx")
        if (insideView)
          return (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${getDirectUrl()}`}
              style={{ width: "100%", height: "80vh" }}
              frameBorder="0"
              title="Docx Viewer"
            />
          );
      return (
        <img src={`${baseUrl}/static/word-icon.png`} alt="Word Doc" width={size} height={size} />
      );
    }
    return null;
  };

  useEffect(() => {
    if (!file.presignedUrl) setUsePresigned(false);
  }, [file.presignedUrl]);

  return (
    <>
      <Stack container="true" justifyContent="space-evenly" alignItems="center" mb={3}>
        {/* Delete button */}
        {!isUser && currentLoggedUser && (
          <MDBox
            sx={{
              position: "absolute",
              right: "-10px",
              top: "-10px",
              backgroundColor: "white",
              padding: 1,
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              zIndex: 1,
            }}
          >
            <IconButton
              color="info"
              // className={styles.maps_section_image_delete}
              onClick={onDelete}
            >
              <Icon fontSize="small">cancel</Icon>
            </IconButton>
            {file.recognition && (
              <IconButton color="info" onClick={() => setRecognitionDetailModalOpen(true)}>
                <ImageSearch />
              </IconButton>
            )}
            {file.type === "Background Check Result" &&
              file.name?.includes("yardstik") &&
              !!currentApplicant.yardstik?.report?.status && (
                <IconButton
                  onClick={() => {
                    setYardstikModalOpen(true);
                  }}
                >
                  <Tooltip
                    title={
                      currentApplicant.yardstik?.report?.status
                        ?.replace(/_/g, " ")
                        ?.replace(/\b\w/g, (char) => char.toUpperCase()) ?? ""
                    }
                  >
                    {getIconByYardstikStatus(currentApplicant.yardstik?.report?.status)}
                  </Tooltip>
                </IconButton>
              )}
          </MDBox>
        )}

        {/* File preview */}
        <MDBox
          onClick={handleFileOpen}
          sx={{
            cursor: "pointer",
            "&:hover": { opacity: 0.8 },
            position: "relative",
          }}
        >
          {getFilePreview(size)}
        </MDBox>

        {/* File details and editor */}
        <Stack spacing={1} alignItems="center">
          <MDTypography variant="h6">{file.type}</MDTypography>
          <TextField
            disabled={isUser}
            defaultValue={file.name?.substring(0, file.name.lastIndexOf("."))}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={file.editedName?.substring(0, file.editedName.lastIndexOf("."))}
            onInput={(e) => editFileNameHandler(e, file)}
          />
          {file.isEdited && (
            <Stack direction="row" spacing={1} mt={1}>
              <Tooltip title="Confirm" placement="bottom">
                <CheckIcon
                  color="success"
                  onClick={(e) => confirmFileNameChanges(e, file)}
                  fontSize="large"
                  sx={{
                    "&": { transition: "0.1s" },
                    "&:hover": { background: "#D3D3D3" },
                  }}
                />
              </Tooltip>
              <Tooltip title="Cancel" placement="bottom">
                <CloseIcon
                  color="error"
                  onClick={(e) => cancelFileNameChanges(e, file)}
                  fontSize="large"
                  sx={{
                    "&": { transition: "0.1s" },
                    "&:hover": { background: "#D3D3D3" },
                  }}
                />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>
      {file.recognition && recognitionDetailModalOpen && (
        <RecognitionDetailsModal
          file={file}
          getFilePreview={getFilePreview}
          open={recognitionDetailModalOpen}
          setOpen={setRecognitionDetailModalOpen}
        />
      )}
      {currentApplicant.yardstik?.report && yardstikModalOpen && (
        <YardstikAttachmentModal
          getFilePreview={getFilePreview}
          open={yardstikModalOpen}
          setOpen={setYardstikModalOpen}
        />
      )}
    </>
  );
};

export default FileViewer;
