import { useAppContextController } from "context/AppContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import {
  AutoAwesome,
  ContactPage,
  FolderCopy,
  Image,
  Info,
  Settings,
  Timeline,
} from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useCompanyCache from "hooks/useCompanyCache";
import CompanyTable from "layouts/pages/companies/components/CompanyTable";
import useCompanyStore from "stores/useCompanyStore";
import CompanySettings from "layouts/pages/companies/components/CompanySettings";
import CompanyAISettings from "layouts/pages/companies/components/CompanyAISettings";
import CompanyInfoPanel from "./components/CompanyInfoPanel/index";
import CustomerActivities from "./components/CustomerActivities";
import CompanyContent from "./components/CompanyContent";
import CompanyMedia from "./components/CompanyMedia";
import CompanyContacts from "./components/CompanyContacts";
import AdditionalInformation from "./components/AdditionalInformation";

const CompanyPage = ({ mode = "edit" }) => {
  const navigate = useNavigate();
  const { setDataType } = useCompanyStore((state) => ({
    setDataType: state.setDataType,
  }));
  const {
    company,
    setCompany,
    setCompanyType,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
  } = useAppContextController();
  const { refetchCompany } = useCompanyCache({ company, setCompany, setCompanyType });
  const { id, slug, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  const [selectedPanel, setSelectedPanel] = useState("activities");
  const [actionSelected, setActionSelected] = useState(action || "");
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    if (id && (actionSelected || action)) {
      setFilters({ _id: selectedId });
    }
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
    }
  }, [selectedId, action, actionSelected, id]);

  useEffect(() => {
    setDataType("company");
  }, []);

  const setNavigateUrl = (url) => navigate(url);

  const renderPanel = (selection) => {
    switch (selection) {
      case "activities":
        return <CustomerActivities />;
      case "additionalInfo":
        return <AdditionalInformation />;
      case "contacts":
        return <CompanyContacts />;
      case "images":
        return <CompanyMedia />;
      case "companyContent":
        return <CompanyContent />;
      case "settings":
        return <CompanySettings />;
      case "AISettings":
        return <CompanyAISettings />;
      default:
        return null;
    }
  };

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => setSelectedPanel(panel));
    } else {
      setSelectedPanel(panel);
    }
  };

  const panelOptions = [
    {
      title: "Activities",
      icon: <Timeline />,
      hasShadow: selectedPanel === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handlePanelChange("activities"),
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: selectedPanel === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handlePanelChange("additionalInfo"),
    },
    {
      title: "Contacts",
      icon: <ContactPage />,
      hasShadow: selectedPanel === "contacts" ? "emd" : "",
      color: "warning",
      ariaLabel: "contacts",
      fn: () => handlePanelChange("contacts"),
    },
    {
      title: "Image Gallery",
      icon: <Image />,
      hasShadow: selectedPanel === "images" ? "emd" : "",
      color: "",
      ariaLabel: "image gallery",
      fn: () => handlePanelChange("images"),
    },
    {
      title: "Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "settings",
      fn: () => handlePanelChange("settings"),
    },
    {
      title: "Company Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "companyContent" ? "emd" : "",
      color: "primary",
      ariaLabel: "company content",
      fn: () => handlePanelChange("companyContent"),
    },
    ...(company?.aiInterviewsAvailable === "Yes"
      ? [
          {
            title: "Company AI Settings",
            icon: <AutoAwesome />,
            hasShadow: selectedPanel === "AISettings" ? "emd" : "",
            color: "violet",
            ariaLabel: "Company AI Settings",
            fn: () => handlePanelChange("AISettings"),
          },
        ]
      : []),
  ];

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          {/* <CompanyFilterCards setFilters={setFilters} setPage={setPage} /> */}
          <CompanyTable
            id={id}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Companies"
            page={page}
            setPage={setPage}
          />
        </Grid>
        {["companyinfo", "companyall", ""].includes(actionSelected) && (id || mode === "create") && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <CompanyInfoPanel
                  mode={mode}
                  handleSubmit={() => refetchCompany()}
                  panelOptions={panelOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                {renderPanel(selectedPanel)}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default CompanyPage;
