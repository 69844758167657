import React, { useEffect, useState } from "react";
import Input from "@mui/material/Input";
import Label from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const FormEditor = ({ formData, onChange }) => {
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    if (formData && formData.form) {
      setFormFields(formData.form);
    }
  }, [formData]);

  const handleFieldChange = (index, field, value) => {
    const updatedFields = formFields.map((fieldData, i) => {
      if (i === index) {
        return { ...fieldData, [field]: value };
      }
      return fieldData;
    });
    setFormFields(updatedFields);
    onChange({ ...formData, form: updatedFields });
  };

  return (
    <div>
      {formFields.map((field, index) => (
        <Card key={`${index.toString()}_formFields`} className="mb-4">
          <CardContent className="space-y-4">
            <h3 className="text-lg font-medium">Field {index + 1}</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor={`component-${index}`}>Component</Label>
                <Input
                  id={`component-${index}`}
                  value={field.component || ""}
                  onChange={(e) => handleFieldChange(index, "component", e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor={`variant-${index}`}>Variant</Label>
                <Input
                  id={`variant-${index}`}
                  value={field.variant || ""}
                  onChange={(e) => handleFieldChange(index, "variant", e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor={`text-${index}`}>Text</Label>
                <Input
                  id={`text-${index}`}
                  value={field.text || ""}
                  onChange={(e) => handleFieldChange(index, "text", e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor={`name-${index}`}>Name</Label>
                <Input
                  id={`name-${index}`}
                  value={field.name || ""}
                  onChange={(e) => handleFieldChange(index, "name", e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor={`label-${index}`}>Label</Label>
                <Input
                  id={`label-${index}`}
                  value={field.label || ""}
                  onChange={(e) => handleFieldChange(index, "label", e.target.value)}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default FormEditor;
