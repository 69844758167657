import { Snackbar } from "@material-ui/core";
import AttachFile from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import { Alert, Autocomplete, Button, Dialog, DialogContent, Grid } from "@mui/material";
import useDropdowns from "hooks/useDropdowns";
import { makeStyles } from "@mui/styles";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { baseAxios } from "config";
import FormField from "layouts/applications/wizard/components/FormField";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import generateApplicantPresignedUrl from "api/applicants/generateApplicantPresignedUrl";

const useStyle = makeStyles({
  dropzone: {
    height: 170,
  },
});

const UploadFileModal = ({
  currentApplicant,
  setCurrentApplicant,
  open,
  setOpen,
  refresh,
  updateApplicantAction = null,
  outsideMode = "",
}) => {
  const classes = useStyle();
  // const { currentApplicant, setCurrentApplicant } = useAppContextController();
  const handleClose = () => setOpen(false);
  const [attachmentTitle, setAttachmentTitle] = useState(null);
  const [attachmentType, setAttachmentType] = useState(null);
  const [missingMeta, setMissingMeta] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const { dropdowns: attachmentTypes } = useDropdowns({
    entity: "attachmentTypes",
    outsideMode,
  });
  const queryClient = useQueryClient();
  const handleInputChange = (e) => {
    e.preventDefault();
    setAttachmentTitle(e.target.value);
  };
  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, { data }) => {
      setToastAlert({ isShow: true, message: "Attachment has been uploaded!", status: "success" });
      if (refresh) await refresh();
      if (currentApplicant && setCurrentApplicant)
        setCurrentApplicant({ ...currentApplicant, ...data });
    },
  });
  const onDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const uploadFile = async (form, fileName, fileType) => {
        try {
          const uploadRes = await baseAxios.post(
            `/${outsideMode ? `outside-${outsideMode}/` : ""}upload/applicants/${
              currentApplicant?._id
            }/${attachmentType}`,
            form
          );

          let newAtt = {
            title: attachmentTitle || fileName,
            type: attachmentType,
            docType: fileType,
            filename: fileName,
            uploadDate: new Date(),
          };

          if (!outsideMode) {
            try {
              const body = { fileType, fileName };
              const response = await generateApplicantPresignedUrl({
                applicantId: currentApplicant._id,
                body,
              });

              const { presignedUrl } = response.data;
              newAtt = { ...newAtt, presignedUrl: presignedUrl || "" };
            } catch (presignedError) {
              console.error("Failed to generate presigned URL:", presignedError);
              setToastAlert({
                isShow: true,
                message: "Failed to generate file URL. Please try again.",
                status: "error",
              });
              throw presignedError;
            }
          }

          const newAttachmentsArr = currentApplicant.attachments
            ? [...currentApplicant.attachments, newAtt]
            : [newAtt];

          if (!updateApplicantAction) {
            await updateApplicantMutation.mutateAsync({
              applicantId: currentApplicant?._id,
              data: {
                attachments: [...newAttachmentsArr],
              },
            });
          } else {
            updateApplicantAction(currentApplicant?._id, {
              attachments: [...newAttachmentsArr],
            });
          }
        } catch (error) {
          console.log("Upload failed:", error.response?.data);
          let errorMessage =
            error?.response?.data?.message ?? "Failed to upload file. Please try again.";

          // Handle specific error cases
          if (error?.response?.status === 413) {
            errorMessage = "File size too large. Please choose a smaller file.";
          } else if (error?.response?.status === 415) {
            errorMessage = "Invalid file type. Please choose a different file.";
          } else if (error?.response?.status === 401) {
            errorMessage = "Session expired. Please log in again.";
          }

          setToastAlert({
            isShow: true,
            message: errorMessage,
            status: "error",
          });
          throw error;
        }
      };

      const file = acceptedFiles[0];
      const fileType = file.path.split(".").pop();
      const form = new FormData();
      form.append("file", file);
      if (currentApplicant?._id) {
        setIsUploading(true);
        try {
          await uploadFile(form, file.path, fileType);
          setAttachmentTitle(null);
          setAttachmentType(null);
          setOpen(false);
        } catch (error) {
          // Error already handled in uploadFile function
        } finally {
          setIsUploading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (attachmentType !== null) {
      setMissingMeta(false);
    }
    if (attachmentType === null) {
      setMissingMeta(true);
    }
  }, [attachmentType]);
  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
      >
        <DialogContent sx={{ padding: "24px", maxWidth: "480px", width: "400px" }}>
          <FlexWrapper justifyContent="space-between" sx={{ flexWrap: "nowrap" }}>
            <MDTypography sx={{ flex: 1 }}>
              <AttachFile color="error" /> Upload File
            </MDTypography>
            <MDBox sx={{ flex: 0 }}>
              <Button
                variant="text"
                disabled={isUploading}
                startIcon={<CancelIcon />}
                onClick={() => {
                  setAttachmentTitle(null);
                  setAttachmentType(null);
                  setOpen(false);
                }}
                sx={(theme) => ({
                  [theme.breakpoints.down(375)]: {
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  },
                })}
              >
                Cancel
              </Button>
            </MDBox>
          </FlexWrapper>
          <Grid item lg={8}>
            <Autocomplete
              options={attachmentTypes || []}
              disabled={isUploading}
              onChange={(e, v) => {
                setAttachmentType(v);
              }}
              renderInput={(params) => (
                <FormField label="Attachment Type" {...params} type="text" />
              )}
            />
          </Grid>
          <Grid item lg={8}>
            <FormField
              disabled={isUploading}
              variant="standard"
              label="Name or Title"
              value={attachmentTitle || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <MDBox mt={2}>
            <MDTypography variant="h5" color="info">
              Upload File
            </MDTypography>
            <MDBox
              onClick={() => {
                if (missingMeta)
                  setToastAlert({
                    isShow: true,
                    message: "You need fill an Attachment Type",
                    status: "error",
                  });
              }}
            >
              <FileDropzone
                disabled={missingMeta || isUploading}
                multiple={false}
                message={
                  missingMeta ? "You must fill Attachment Type" : "Drop files here to upload"
                }
                className={classes.dropzone}
                onDrop={onDropHandler}
              />
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadFileModal;
