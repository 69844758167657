import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { Grid } from "@material-ui/core";

const ComponentMap = {
  TextField: ({ label, name, value, onChange, type = "text", ...props }) => (
    <TextField
      fullWidth
      label={label}
      name={name}
      value={value ?? ""}
      onChange={(e) => {
        onChange({ [name]: e.target.value });
      }}
      type={type}
      margin="normal"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "primary.main",
          },
          '& input[type="date"]::-webkit-calendar-picker-indicator': {
            cursor: "pointer",
          },
        },
        "& .MuiInputBase-input": {
          height: "1.4375em",
          padding: "8.5px 14px",
        },
      }}
      {...props}
    />
  ),
  Select: ({ label, name, value, onChange, options = [] }) => (
    <FormControl fullWidth margin="normal" size="small">
      <InputLabel shrink>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value ?? ""}
        onChange={(e) => onChange({ [name]: e.target.value })}
        displayEmpty
        sx={{
          "& .MuiSelect-select": {
            padding: "8.5px 14px",
            minHeight: "1.4375em",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.main",
          },
        }}
      >
        <MenuItem value="">
          <em>Select {label}</em>
        </MenuItem>
        {Array.isArray(options) &&
          options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  ),
  Typography: ({ text, variant }) => (
    <Box sx={{ width: "100%", mt: 4, mb: 2 }}>
      <Typography
        variant={variant}
        sx={{
          fontWeight: 600,
          color: "primary.main",
          position: "relative",
          pb: 1,
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "2px",
            backgroundColor: "primary.main",
            opacity: 0.2,
          },
        }}
      >
        {text}
      </Typography>
    </Box>
  ),
  FormControlLabel: ({ label, control, ...props }) => (
    <FormControlLabel
      control={React.createElement(ComponentMap[control.component], { ...control })}
      label={label}
      {...props}
    />
  ),
  Checkbox: ({ ...props }) => <Checkbox {...props} />,

  Radio: ({ name, ...props }) => <Radio name={name} {...props} />,

  RadioGroup: ({ name, value, onChange, options }) => (
    <FormControl component="fieldset" margin="normal">
      <RadioGroup
        name={name}
        value={value ?? ""}
        onChange={(e) => {
          onChange({ [name]: e.target.value });
        }}
      >
        {options.map((option) => (
          <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
        ))}
      </RadioGroup>
    </FormControl>
  ),
};

const DynamicForm = ({ formConfig, onChange, formData = {} }) => {
  const handleChange = (fieldUpdate) => {
    console.log("Field update:", fieldUpdate);
    onChange({ ...formData, ...fieldUpdate });
  };

  const renderFormElement = (element) => {
    const Component = ComponentMap[element.component];
    if (!Component) return null;

    const { component, ...elementProps } = element;

    return (
      <Component
        {...elementProps}
        onChange={(update) => handleChange(update)}
        value={formData[element.name] || ""}
      />
    );
  };

  // Function to recursively render form elements
  const renderFormElements = (elements) => {
    return elements.map((element, index) => {
      if (element.component === "Typography" || element.props?.variant) {
        return (
          <Grid xs={12} sm={12} key={`${index.toString()}_component`}>
            {renderFormElement(element)}
          </Grid>
        );
      }

      // Get name from either direct property or nested props
      const name = element.name || element.props?.name;

      // Determine field width based on type and name
      let fieldWidth = 12;
      if (name?.includes("Initial") || name?.includes("MI") || name?.match(/gender$/i)) {
        fieldWidth = 2;
      } else if (
        name?.includes("zip") ||
        name?.includes("state") ||
        name?.match(/height$/i) ||
        name?.match(/weight$/i)
      ) {
        fieldWidth = 4;
      } else if (
        element.component === "Select" ||
        name?.includes("city") ||
        name?.includes("firstName") ||
        name?.includes("lastName") ||
        element.type === "date" ||
        element.props?.type === "date"
      ) {
        fieldWidth = 6;
      }

      // Handle nested children
      if (element.children && Array.isArray(element.children)) {
        return (
          <Grid container spacing={2} key={`${index.toString()}_element`}>
            {renderFormElements(element.children)}
          </Grid>
        );
      }

      return (
        <Grid xs={12} sm={fieldWidth} key={`${index.toString()}_field`} sx={{ display: "flex" }}>
          {renderFormElement(element)}
        </Grid>
      );
    });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        backgroundColor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Stack spacing={2}>
        {renderFormElements(Array.isArray(formConfig.form) ? formConfig.form : [formConfig.form])}
      </Stack>
    </Paper>
  );
};

const CandidateForm = ({ onChange, formData = {}, customForm = null }) => {
  if (customForm && typeof customForm === "object" && customForm.form) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          "& .MuiFormControl-root": {
            mb: 1,
          },
        }}
      >
        <DynamicForm formConfig={customForm} onChange={onChange} formData={formData} />
      </Box>
    );
  }

  if (React.isValidElement(customForm)) {
    return React.cloneElement(customForm, { onChange, formData });
  }

  if (typeof customForm === "function") {
    const renderedForm = customForm({ onChange, formData });
    if (React.isValidElement(renderedForm)) {
      return renderedForm;
    }
  }

  return <div>No form configuration provided</div>;
};

CandidateForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default CandidateForm;
