import { Icon, IconButton, TextField, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { baseAxios } from "config";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import useStyles from "layouts/pages/events/components/EventsInformation/Attachments/styles";
import ImagePreviewModal from "layouts/pages/events/components/EventsInformation/ImagePreviewModal";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import { useSnackbar } from "notistack";

const Attachments = ({ currentEvent, setCurrentEvent, setAttachmentOpen }) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const queryClient = useQueryClient();
  const { company, setCompany, setCompanyType } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const renameFileMutation = useMutation(
    async ({ rename, original }) => {
      const url = new URL(
        `/upload/rename/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}`
      );
      url.searchParams.append("rename", rename);
      url.searchParams.append("original", original);
      await baseAxios.patch(url.toString());
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: () => {
        setToastAlert({ isShow: true, message: "Successfully renamed", status: "success" });
      },
    }
  );

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("events");
      setCurrentEvent({ ...currentEvent, ...data });
      enqueueSnackbar("Attachment has been uploaded!", { variant: "success" });
    },
  });

  const addNew = (e) => {
    setAttachmentOpen(true);
  };

  const deleteFileHandler = async (e, idx) => {
    const newAttachmentsArr = currentEvent?.attachments?.filter((file, index) => index !== idx);
    await updateEventMutation.mutateAsync({
      eventId: currentEvent._id,
      data: {
        attachments: newAttachmentsArr,
      },
    });
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];

    // get the image extension
    const imgExt = newFiles[idx].name.substring(newFiles[idx].name.lastIndexOf("."));

    // check if edited
    const removeExt = newFiles[idx].name.substring(0, newFiles[idx].name.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;

    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].name;

    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const confirmFileNameChanges = async (e, idx) => {
    await renameFileMutation.mutateAsync({
      original: files[idx].name,
      rename: files[idx].editedName,
    });

    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], name: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);

    await updateEvent.mutateAsync({
      eventId: currentEvent._id,
      data: { attachments: newFiles.map((img) => img.name) },
    });
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );

  useEffect(() => {
    if (currentEvent?.attachments?.length) {
      setFiles(
        currentEvent?.attachments.map((file) => {
          const { docType, filename: name, title, type, uploadDate } = file;
          return { docType, name, title, type };
        })
      );
    } else {
      setFiles([]);
    }
  }, [currentEvent?.attachments]);

  return (
    <MDBox className={styles.otherSection}>
      <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
        <MDTypography component="label" variant="button" fontWeight="bold" color="info">
          ADDITIONAL EVENT ATTACHMENTS
        </MDTypography>
      </MDBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={1.5}>
          <MDBox mb={2}>
            <IconButton className={styles.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
        <Grid item sm={10.5}>
          <MDBox className={styles.section_images_wapper}>
            {files.map((file, idx) => {
              return (
                <MDBox
                  className={styles.section_image_container}
                  key={file ? file.name : `filename_${idx}`}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid>
                      <MDBox
                        style={{
                          position: "absolute",
                          right: "-15px",
                          top: "-15px",
                          backgroundColor: "white",
                          padding: 1,
                          borderRadius: "25px",
                        }}
                      >
                        <IconButton
                          color="info"
                          // className={styles.maps_section_image_delete}
                          onClick={(e) => deleteFileHandler(e, idx)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                      </MDBox>
                    </Grid>

                    <Grid>
                      {["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg"].includes(
                        file?.docType?.toLowerCase()
                      ) && (
                        <MDBox
                          onClick={() => {
                            setPreviewFile(file);
                            setImagePreviewOpen(true);
                          }}
                        >
                          <img
                            src={`${company?.imageUrl}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${file?.name}`}
                            alt="previews"
                            className={styles.section_image}
                          />
                        </MDBox>
                      )}
                      {file?.docType?.toLowerCase() === "pdf" && (
                        <MDBox
                          onClick={() => {
                            window.open(
                              `${company?.imageUrl}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${file?.name}`
                            );
                          }}
                        >
                          <img
                            src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                            alt="preview"
                            width={100}
                            height={100}
                          />
                        </MDBox>
                      )}
                      {file?.docType?.toLowerCase() === "docx" && (
                        <MDBox
                          onClick={() => {
                            window.open(
                              `${company?.imageUrl}/${currentEvent?.venueSlug}/events/${currentEvent?.eventUrl}/${file?.name}`
                            );
                          }}
                        >
                          <img
                            src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                            alt="preview"
                            width={100}
                            height={100}
                          />
                        </MDBox>
                      )}
                    </Grid>

                    <Grid>
                      <TextField
                        defaultValue={file?.name?.substring(0, file?.name?.lastIndexOf("."))}
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                        value={files[idx].editedName?.substring(
                          0,
                          file?.editedName?.lastIndexOf(".")
                        )}
                        onInput={(e) => editFileNameHandler(e, idx)}
                      />

                      {file?.isEdited && (
                        <>
                          <Tooltip title="Confirm" placement="bottom">
                            <CheckIcon
                              color="success"
                              onClick={(e) => confirmFileNameChanges(e, idx)}
                              fontSize="large"
                              sx={{
                                "&": { transition: "0.1s" },
                                "&:hover": { background: "#D3D3D3" },
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom">
                            <CloseIcon
                              color="error"
                              onClick={(e) => cancelFileNameChanges(e, idx)}
                              fontSize="large"
                              sx={{
                                "&": { transition: "0.1s" },
                                "&:hover": { background: "#D3D3D3" },
                              }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              );
            })}
          </MDBox>
        </Grid>
      </Grid>
      <ImagePreviewModal
        imagePreviewOpen={imagePreviewOpen}
        setImagePreviewOpen={setImagePreviewOpen}
        currentEvent={currentEvent}
        previewFile={previewFile}
      />
    </MDBox>
  );
};

export default Attachments;
