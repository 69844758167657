import React, { useCallback, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import FileUpload from "@mui/icons-material/FileUpload";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CandidateForm from "layouts/pages/setup/components/DynamicForm/higherOrderForm";
import FormEditor from "layouts/pages/setup/components/DynamicForm/FormEditor";
import getDynamicFormData from "api/llm/getDynamicFormData";

const DynamicForms = () => {
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [processedForms, setProcessedForms] = useState([]);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const processFiles = async (filesToProcess) => {
    setProcessing(true);
    setError(null);

    try {
      const form = new FormData();
      form.append("file", filesToProcess[0]);

      const response = await getDynamicFormData({
        form,
      });

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const formConfig = await response.data;

      // Initialize form data based on form config
      const initialFormData = {};
      formConfig.form.forEach((field) => {
        if (field.name) {
          initialFormData[field.name] = "";
        }
      });

      setProcessedForms([
        {
          fileName: filesToProcess[0].name,
          formData: initialFormData,
          customForm: formConfig,
        },
      ]);
    } catch (err) {
      setError("Error processing files. Please try again.");
      console.error("Processing error:", err);
    } finally {
      setProcessing(false);
    }
  };

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const droppedFiles = [...e.dataTransfer.files];
    const validFiles = droppedFiles.filter((file) => {
      const type = file.type.toLowerCase();
      return type === "application/pdf" || type.startsWith("image/");
    });

    if (validFiles.length === 0) {
      setError("Please upload PDF or image files only");
      return;
    }

    setFiles(validFiles);
    await processFiles(validFiles);
  }, []);

  const handleFileInput = useCallback(async (e) => {
    // Only allow single file
    const selectedFiles = [...e.target.files].slice(0, 1);
    setFiles(selectedFiles);
    await processFiles(selectedFiles);
  }, []);

  const handleFormChange = (index, newFormData) => {
    console.log("Form update:", { index, newFormData });
    setProcessedForms((prevForms) => {
      const newForms = [...prevForms];
      newForms[index] = {
        ...newForms[index],
        formData: { ...newForms[index].formData, ...newFormData },
      };
      console.log("Updated form:", newForms[index].formData);
      return newForms;
    });
  };

  const sendMicroServeRequest = async () => {
    try {
      const data = {
        slug: "mend-io",
        tenant: "sampleTenant",
        embeddingCollection: "documentEmbeddings",
        prospectCollection: "prospects",
      };

      const response = await fetch("http://localhost:5010/llm/dynamicForms", {
        method: "POST",
        body: JSON.stringify(data),
      });
      console.log("Microservice response:", response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Microservice result:", result);
    } catch (err) {
      alert("Error in storm Please try again.");
    }
  };

  return (
    <Card sx={{ width: "100%", maxWidth: "md", mx: "auto" }}>
      <CardContent>
        <Box
          sx={{
            border: "2px dashed #d1d5db",
            borderRadius: "0.5rem",
            padding: "2rem",
            textAlign: "center",
            backgroundColor: dragActive ? "#eff6ff" : "transparent",
            borderColor: dragActive ? "#60a5fa" : "#d1d5db",
            cursor: "pointer",
            width: "100%",
            mx: "auto",
            position: "relative",
          }}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
            accept="application/pdf,image/*"
            onChange={handleFileInput}
          />
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <FileUpload sx={{ fontSize: 60, color: "grey.400" }} />
            <Box>
              <Typography variant="h6" fontWeight="medium">
                Drop your file here or click to upload
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                Supports PDF and image files
              </Typography>
            </Box>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mt: 4 }}>
            <ErrorOutline sx={{ mr: 1 }} />
            {error}
          </Alert>
        )}

        {processing && (
          <Box mt={4} textAlign="center">
            <CircularProgress />
            <Typography variant="body1" mt={1}>
              Processing files...
            </Typography>
          </Box>
        )}

        {processedForms.length > 0 && !processing && (
          <Box mt={6} gap={3} display="flex" flexDirection="column">
            {processedForms.map((form, index) => (
              <Card key={`${index.toString()}_processdField`} sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight="medium" mb={2}>
                  {form.fileName}
                </Typography>
                <CandidateForm
                  onChange={(newData) => handleFormChange(index, newData)}
                  formData={form.formData}
                  customForm={form.customForm}
                />
                <FormEditor
                  formData={form.customForm}
                  onChange={(newJson) => handleFormChange(index, newJson)}
                />
              </Card>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DynamicForms;
